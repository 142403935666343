<template>
  <div class="read-message-wrapper">
    <el-card>
      <SearchBar slot="header" @handleSearchMessage="handleSearchMessage" />
      <MessageTable :message="message" :height="tHeight" />
      <TabbedBrowsing :page="page" :size="size" :total="total" @handleCurrentChange="handleCurrentChange" />
    </el-card>
  </div>
</template>

<script>
import SearchBar from '../components/SearchBar.vue';
import MessageTable from '../components/MessageTable.vue';
import TabbedBrowsing from '../components/TabbedBrowsing.vue';
import mixin from '../mixin.js';
import statusInclude from '@/utils/statusCode.js';
export default {
  name: 'ReadMessage',
  components: {
    SearchBar,
    MessageTable,
    TabbedBrowsing,
  },
  mixins: [mixin],
  data() {
    return {
      page: 1,
      size: 13,
      total: 100,
      message: [],
      locating: {
        msgType: 0,
        searchContent: '',
        searchType: '',
      },
    };
  },
  mounted() {
    this.getSourceSize(200).then((size) => {
      this.size = size;
      this.getMessageList(this.page, this.size, 1, '', '');
    });
  },
  methods: {
    //按名称搜索
    handleSearchMessage(info) {
      const { msgType, searchContent, searchType } = info;
      this.locating = info;
      this.getMessageList(this.page, this.size, msgType, searchContent, searchType);
    },
    handleCurrentChange(page) {
      this.page = page;
      const { msgType, searchContent, searchType } = this.locating;
      this.getMessageList(this.page, this.size, msgType, searchContent, searchType);
    },
    getMessageList(page, size, msgType, searchContent = '', searchType = '', status = 1) {
      this.getNotictMsgList(page, size, msgType, searchContent, searchType, status).then((response) => {
        const { code, reason, data } = response;
        console.log(response);
        if (!statusInclude(code)) return this.$message({ type: 'warning', message: reason });
        const { records, total } = data;
        this.message = records;
        this.total = total;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-card__body {
  position: relative;
  height: calc(100vh - #{$offset});
}
</style>
